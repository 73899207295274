/* eslint-disable @typescript-eslint/no-empty-interface */
import { autoinject } from 'aurelia-framework';
import { Notify } from 'utils/notify';
import environment from '../../config/environment.json';

export let actorTypes = {
  ADMIN: "ActorType.ADMIN",
  RESTAURANT: "ActorType.RESTAURANT",
  CUSTOMER: "ActorType.CUSTOMER",
};


/** This is a Date-preserving deepClone. */
export function deepClone(x: unknown): any {
  if (x instanceof Date) {
    return new Date(x.getTime());
  } else if (x instanceof Array) {
    return x.map(value => deepClone(value));
  } else if (typeof x === "object") {
    if (!x) {
      return x;
    } else {
      let copy: { [value: string]: any; } = {};
      for (let kv of Object.entries(x)) {
        copy[kv[0]] = deepClone(kv[1]);
      }
      return copy;
    }
  } else {
    return x;
  }
}  

/// GENERATED BEGIN ///
export interface ADSessionByIdInternalRequest {
	sessionId: string;
}

export interface ADSessionLogin2InternalRequest {
	code: string;
	state: string;
}

export interface AccountGetPasswordActorInternalRequest {
	m: string;
}

export interface AccountRegisterRequest {
	storeId?: number;
	authenticationType: AuthenticationType;
	email?: string;
	phone?: string;
	firstName: string;
	lastName: string;
}

export interface AccountRequestSetPasswordRequest {
	storeId?: number;
	email: string;
}

export interface AccountRequestSetPasswordResponse {
	username: string;
}

export interface AccountSetPasswordRequest {
	m: string;
	password: string;
}

export interface Actor {
	id: number;
	createActorId?: number;
	createTime: Date;
	modifyActorId?: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	actorType: ActorType;
	businessGroupId?: number;
	businessUnitId?: number;
	authenticationType?: AuthenticationType;
	email?: string;
	phone?: string;
	firstName: string;
	lastName: string;
}

export interface ActorAdminByIdInternalRequest {
	id: number;
}

export interface ActorAdminUpdateRequest {
	id?: number;
	actorType: ActorType;
	email?: string;
	password?: string;
	firstName: string;
	lastName: string;
	phone?: string;
	businessGroupId?: number;
	businessUnitId?: number;
	delete: boolean;
}

export interface ActorListResponse {
	id: number;
	createdBy: string;
	firstName: string;
	lastName: string;
	email: string;
	actorType: ActorType;
	businessGroupName: string;
	businessUnitName: string;
}

export type ActorType = "ADMIN" | "RESTAURANT" | "CUSTOMER";

export interface ActorUpdateSelfRequest {
	firstName: string;
	lastName: string;
	oldPassword?: string;
	password?: string;
	delete: boolean;
}

export type AuthenticationType = "EMAIL" | "PHONE";

export type AutoSelectMode = "MANUAL" | "FIRST_POSSIBLE" | "FIRST_AND_HIDE";

export interface BusinessGroup {
	id: number;
	deleteTime?: Date;
	name: string;
}

export interface BusinessGroupByIdInternalRequest {
	id: number;
}

export interface BusinessUnit {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	businessGroupId: number;
	posClient: string;
	posLocation: string;
	unitOfficialIdentifier: string;
	unitOfficialName: string;
	name: string;
	openingDate: Date;
	closingDate?: Date;
	addressName: string;
	addressName2?: string;
	city: string;
	zip: string;
	email: string;
	phone: string;
}

export interface BusinessUnitBrand {
	businessUnitId: number;
	modifyTime: Date;
	bannerData?: string;
	bannerMimeType?: string;
	logoData?: string;
	logoMimeType?: string;
	primaryBrandColor: string;
	primaryBrandColorText: string;
	secondaryBrandColor: string;
	secondaryBrandColorText: string;
	actionBrandColor: string;
	actionBrandColorText: string;
}

export interface BusinessUnitBrandByIdInternalRequest {
	id: number;
}

export interface BusinessUnitByIdInternalRequest {
	id: number;
}

export interface BusinessUnitPaymentInfo {
	businessUnitId: number;
	merchantAgreementCode: string;
	publicKey: string;
}

export interface BusinessUnitPaymentInfoByIdInternalRequest {
	businessUnitId: number;
}

export interface BusinessUnitPaymentInfoMakeRequest {
	businessUnitId: number;
	merchantAgreementCode: string;
}

export interface BusinessUnitPaymentInfoUpdateRequest {
	businessUnitId: number;
	merchantAgreementCode: string;
	delete: boolean;
}

export interface BusinessUnitUpdateRequest {
	id?: number;
	businessGroupId: number;
	unitOfficialIdentifier: string;
	unitOfficialName: string;
	name: string;
	posClient: string;
	posLocation: string;
	addressName: string;
	addressName2?: string;
	zip: string;
	city: string;
	email: string;
	phone: string;
	openingDate: Date;
	closingDate?: Date;
	bannerData?: string;
	bannerMimeType?: string;
	logoData?: string;
	logoMimeType?: string;
	primaryBrandColor: string;
	primaryBrandColorText: string;
	secondaryBrandColor: string;
	secondaryBrandColorText: string;
	actionBrandColor: string;
	actionBrandColorText: string;
	delete: boolean;
}

export interface CalculatedProductInventory {
	productId: number;
	inventoryTime?: Date;
	amount?: number;
	sold: number;
}

export interface ChangePasswordRequest {
	m: string;
	password: string;
}

export interface ChangePasswordWithSessionRequest {
	oldPassword: string;
	password: string;
}

export type DayOfWeek = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";

export interface DiscountGroup {
	id: number;
	businessUnitId: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	name: string;
	isGeneral: boolean;
	code?: string;
}

export interface DiscountGroupByIdInternalRequest {
	id: number;
}

export interface DiscountGroupByIdResult {
	discountGroup: DiscountGroup;
	row: DiscountGroupRow[];
}

export interface DiscountGroupListInternalRequest {
	buId: number;
}

export interface DiscountGroupListNonGeneralInternalRequest {
	buId: number;
}

export interface DiscountGroupRow {
	id: number;
	deleteActorId?: number;
	deleteTime?: Date;
	discountGroupId: number;
	productCategoryId?: number;
	productId?: number;
	value: number;
}

export interface DiscountGroupRowUpdateRequest {
	productCategoryId?: number;
	productId?: number;
	value: number;
}

export interface DiscountGroupUpdateRequest {
	id?: number;
	businessUnitId: number;
	name: string;
	code?: string;
	isGeneral: boolean;
	delete: boolean;
	row: DiscountGroupRowUpdateRequest[];
}

export interface EmailDiscount {
	id: number;
	businessUnitId: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	email: string;
	discountGroupId: number;
}

export interface EmailDiscountByIdInternalRequest {
	id: number;
}

export interface EmailDiscountDeleteRequest {
	id: number;
}

export interface EmailDiscountDownloadInternalRequest {
	businessUnitId: number;
}

export interface EmailDiscountListInternalRequest {
	businessUnitId: number;
}

export interface EmailDiscountUpdateOneRequest {
	id?: number;
	businessUnitId: number;
	discountGroupId: number;
	email: string;
}

export interface EmailDiscountUpdateRequest {
	businessUnitId: number;
	list: EmailDiscountUpdateRequestRow[];
}

export interface EmailDiscountUpdateRequestRow {
	email: string;
	name: string[];
}

export interface EmailDiscountUploadRequest {
	businessUnitId: number;
	excel: string;
}

export type EmailPurpose = "NewAccountEmail" | "ConfirmEmail" | "ForgotPasswordEmail" | "TuloPOSExplanation" | "OrderAccepted" | "OrderAcceptedDelay" | "OrderCancel" | "OrderPickup" | "OrderPreparing" | "OrderPreparingDelay" | "ReceiptEmail";

export interface Extra {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	businessUnitId: number;
	extraPrice: number;
	name: string;
	nameEn?: string;
	nameSv?: string;
	outOfStock?: Date;
}

export interface ExtraByIdInternalRequest {
	id: number;
}

export interface ExtraExtraGroup {
	extraId: number;
	extraGroupId: number;
}

export interface ExtraExtraGroupByBusinessUnitIdInternalRequest {
	businessUnitId: number;
}

export interface ExtraExtraGroupByExtraGroupIdInternalRequest {
	extraGroupId: number;
}

export interface ExtraExtraGroupUpdateRequest {
	extraGroupId: number;
	extraIds: number[];
}

export interface ExtraGroup {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	businessUnitId: number;
	mandatory: boolean;
	multiple: boolean;
	minChoiceNumber?: number;
	maxChoiceNumber?: number;
	name: string;
	nameEn?: string;
	nameSv?: string;
	description?: string;
	descriptionEn?: string;
	descriptionSv?: string;
}

export interface ExtraGroupByIdInternalRequest {
	id: number;
}

export interface ExtraGroupListInternalRequest {
	buId: number;
}

export interface ExtraGroupUpdateRequest {
	id?: number;
	businessUnitId: number;
	mandatory: boolean;
	minChoiceNumber?: number;
	maxChoiceNumber?: number;
	multiple: boolean;
	name: string;
	nameSv?: string;
	nameEn?: string;
	description?: string;
	descriptionSv?: string;
	descriptionEn?: string;
	delete: boolean;
}

export interface ExtraListByBusinessUnitIdInternalRequest {
	buId: number;
}

export interface ExtraUpdateRequest {
	id?: number;
	businessUnitId: number;
	extraPrice: number;
	name: string;
	nameEn?: string;
	nameSv?: string;
	outOfStock?: Date;
	delete: boolean;
}

export interface ForgotPasswordGetUsernameChangeInternalRequest {
	m: string;
}

export interface ForgotPasswordRequest {
	email: string;
}

export interface ForgotPasswordUsernameResponse {
	username: string;
}

export interface Form {
}

export interface GdprActor {
	email?: string;
	phone?: string;
	firstName: string;
	lastName: string;
}

export interface GdprDataResponse {
	actor: GdprActor;
	orders: GdprOrder[];
	loginTimes: Date[];
}

export interface GdprOrder {
	id: number;
	createTime: Date;
	status: NKOrderStatus;
	language: Language;
	paymentType: NKPaymentType;
	total: number;
	deliveryMethod: NKDeliveryMethod;
	storeName: string;
	isGiftCard: boolean;
	freeText?: string;
	tableNumber?: string;
	rows: GdprOrderRow[];
	vivaWalletInfos: GdprOrderVivaWalletInfo[];
}

export interface GdprOrderRow {
	productName: string;
	quantity: number;
	price: number;
	total: number;
	vatPercent: number;
	zeroVatTotal: number;
	vatAmount: number;
	extras: string;
}

export interface GdprOrderVivaWalletInfo {
	createTime: Date;
	amount: number;
	status?: string;
	cardNumber?: string;
	cardUniqueReference?: string;
	transactionType: string;
	cardCountryCode?: string;
	cardIssuingBank?: string;
}

export interface GdprSendEmailRequest {
	email: string;
}

export interface GiftCardProduct {
	id: number;
	businessGroupId: number;
	productName: string;
	price: number;
	openPrice: boolean;
	validityDays?: number;
	notAfter?: Date;
	shortDescription?: string;
	deleteTime?: Date;
}

export interface GiftCardProductListInternalRequest {
	buId: number;
}

export type Language = "FI" | "SV" | "EN";

export type NKDeliveryMethod = "FETCH" | "ON_SITE" | "EMAIL";

export interface NKOrPosOrderStatusRequest {
	id: number;
	desiredReadyTime?: Date;
}

export interface NKOrderByIdResponse {
	actor: Actor;
	order: Orders;
	orderVivaWalletInfo?: OrderVivaWalletInfo;
	rowList: NKOrderByIdRowResponse[];
}

export interface NKOrderByIdRowResponse {
	row: OrderRow;
	extraList: OrderRowExtra[];
}

export type NKOrderStatus = "NEW" | "PAYMENT" | "PAYMENT_IN_PROCESS" | "ACCEPTED" | "PREPARED" | "PICKUP" | "DELIVERED" | "DELETED";

export type NKPaymentType = "OnSite" | "PrePaid";

export type NKProductType = "RECIPE" | "REGULAR" | "GIFT_CARD";

export type NKStoreType = "POS" | "GIFT_CARD";

export interface NettikauppaTaskStatusResponse {
	runtimeData: TaskRuntimeData<void>;
	lastFailedThrowable?: string;
}

export interface OrderByIdInternalRequest {
	id: number;
}

export interface OrderGiftCardOrderRequest {
	storeId: number;
	products: OrderGiftCardOrderRequestRow[];
	language: Language;
}

export interface OrderGiftCardOrderRequestRow {
	productId: number;
	quantity: number;
	price: number;
}

export interface OrderListCancelingInternalRequest {
	showSuccess: boolean;
}

export interface OrderListCancelingResponse {
	businessUnitName: string;
	storeName: string;
	id: number;
	deleteTime: Date;
	paymentOrderGrossAmount: number;
	paymentOrderTimestamp: Date;
	paymentTransactionNumber: string;
	paymentOrderNumber: string;
	paymentFilingCode: string;
	paymentRefundStatus: string;
	paymentRefundStatusTimestamp: Date;
}

export interface OrderListInternalRequest {
	storeId?: number;
	orderStatus: NKOrderStatus[];
	startDate?: Date;
	endDate?: Date;
}

export interface OrderPdfInternalRequest {
	id: number;
}

export interface OrderResendPdfRequest {
	id: number;
}

export interface OrderRow {
	id: number;
	ordersId: number;
	parentId?: number;
	productCategoryId: number;
	productCategoryName: string;
	productCategoryWeight: number;
	productSubCategoryId?: number;
	productSubCategoryName?: string;
	productSubCategoryWeight?: number;
	productId: number;
	productType: NKProductType;
	productWeight: number;
	recipeName?: string;
	recipeNameEn?: string;
	recipeNameSv?: string;
	productName: string;
	productNameEn?: string;
	productNameSv?: string;
	discountGroupRowId?: number;
	giftCardProductId?: number;
	discountPercent: number;
	productDescription?: string;
	productDescriptionEn?: string;
	productDescriptionSv?: string;
	quantity: number;
	portion?: number;
	portionName?: string;
	price: number;
	total: number;
	vatPercent: number;
	componentQuantity?: number;
	componentTotal?: number;
	giftCardNumber?: string;
	giftCardPdfUrl?: string;
}

export interface OrderRowExtra {
	id: number;
	orderRowId: number;
	extraGroupId: number;
	extraId: number;
	extraName: string;
	extraNameEn?: string;
	extraNameSv?: string;
	price: number;
}

export interface OrderRowWithExtra extends OrderRow {
	extras: OrderRowExtra[];
}

export interface OrderSendOrderRequest {
	storeId: number;
	products: OrderSendOrderRequestRow[];
	deliveryAddressId?: number;
	deliveryMethod: NKDeliveryMethod;
	deliveryDate: Date;
	readyBy: string;
	readyByDescription?: string;
	paymentType: NKPaymentType;
	tableNumber?: string;
	browserId?: string;
	freeText?: string;
	language: Language;
}

export interface OrderSendOrderRequestRow {
	productId: number;
	portionId?: number;
	discountGroupRowId?: number;
	quantity: number;
	extras: OrderSendOrderRequestRowExtra[];
}

export interface OrderSendOrderRequestRowExtra {
	extraId: number;
	extraGroupId: number;
}

export interface OrderTogether {
	id: number;
	createTime: Date;
	tableNumber: string;
	readyTime?: Date;
}

export interface OrderTogetherParticipant {
	id: number;
	orderTogetherId: number;
	browserId: string;
	createTime: Date;
	clientLastSeen: Date;
	ordersId?: number;
}

export interface OrderVivaWalletInfo {
	id: number;
	createTime: Date;
	modifyTime: Date;
	ordersId: number;
	amount: number;
	transactionId?: string;
	orderCode: string;
	statusId?: VivaWalletStatusId;
	insDate?: string;
	cardNumber?: string;
	currencyCode?: string;
	customerTrns?: string;
	merchantTrns?: string;
	cardUniqueReference?: string;
	transactionTypeId?: number;
	recurringSupport?: boolean;
	totalInstallments?: number;
	cardCountryCode?: string;
	cardIssuingBank?: string;
	currentInstallment?: number;
	cardTypeId?: number;
}

export interface OrderWithDependencies {
	order: OrderWithStatus;
	actor: Actor;
	store: Store;
	businessUnit: BusinessUnit;
	orderTogether?: OrderTogether;
	orderTogetherParticipant?: OrderTogetherParticipant;
}

export interface OrderWithStatus extends Orders {
	status: NKOrderStatus;
	rows: OrderRowWithExtra[];
}

export interface Orders {
	id: number;
	createTime: Date;
	createActorId: number;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	storeId: number;
	deliveryMethod: NKDeliveryMethod;
	desiredReadyTime: Date;
	desiredReadyTimeDescription?: string;
	total: number;
	paymentType: NKPaymentType;
	tableNumber?: string;
	onSiteOrderForwardTime?: Date;
	freeText?: string;
	language: Language;
	acceptedTime?: Date;
	acceptedActorId?: number;
	preparedTime?: Date;
	preparedActorId?: number;
	pickupTime?: Date;
	pickupActorId?: number;
	deliveredTime?: Date;
	deliveredActorId?: number;
	paymentOrderGrossAmount?: number;
	paymentTransactionNumber?: string;
	paymentMethodCode?: string;
	paymentOrderNumber?: string;
	paymentFilingCode?: string;
	paymentOrderTimestamp?: Date;
	paymentFailureReason?: string;
	vivaWalletStatusId?: VivaWalletStatusId;
	paymentRefundStatus?: string;
	paymentRefundTransactionNumber?: string;
	paymentRefundStatusTimestamp?: Date;
	posIntegrationTime?: Date;
}

export interface OutOfStockError {
	productId: number;
	error: string;
	availableAmount: number;
}

export interface PosOrNKExtraOutOfStockRequest {
	id: number;
}

export interface PosOrNKProductOutOfStockRequest {
	productId: number;
}

export interface PosOrNKStoreCloseUpdateRequest {
	storeId: number;
}

export interface Product {
	id: number;
	productType: NKProductType;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	businessUnitId: number;
	productCategoryId: number;
	productSubCategoryId?: number;
	vatCategoryId: number;
	giftCardProductId?: number;
	name: string;
	nameSv?: string;
	nameEn?: string;
	weight: number;
	openPrice: boolean;
	posProduct?: string;
	posSalesChannel?: string;
	storageUnit: number;
	longDescription?: string;
	longDescriptionSv?: string;
	longDescriptionEn?: string;
	allergy?: string;
	shortDescription?: string;
	shortDescriptionSv?: string;
	shortDescriptionEn?: string;
	outOfStock?: Date;
}

export interface ProductByIdInternalRequest {
	id: number;
}

export interface ProductCatalogUpdateRequest {
	storeId: number;
	id?: number;
	discountGroupId?: number;
	startDate: Date;
	endDate?: Date;
}

export interface ProductCategory {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	businessUnitId: number;
	defaultVatCategoryId: number;
	defaultPosProduct: string;
	name: string;
	nameSv?: string;
	nameEn?: string;
	weight: number;
	description?: string;
	descriptionSv?: string;
	descriptionEn?: string;
}

export interface ProductCategoryByIdInternalRequest {
	id: number;
}

export interface ProductCategoryImage {
	productCategoryId: number;
	imageData: string;
	imageMimeType: string;
}

export interface ProductCategoryImageByIdInternalRequest {
	id: number;
}

export interface ProductCategoryListByBuIdInternalRequest {
	buId: number;
}

export interface ProductCategorySalePeriod {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	productCategoryId: number;
	dayOfWeek: DayOfWeek;
	startTime: string;
	endTime: string;
}

export interface ProductCategorySalePeriodsInternalRequest {
	pcId: number;
}

export interface ProductCategoryUpdateRequest {
	id?: number;
	businessUnitId: number;
	defaultPosProduct: string;
	defaultVatCategoryId: number;
	name: string;
	weight: number;
	nameSv?: string;
	nameEn?: string;
	description?: string;
	descriptionSv?: string;
	descriptionEn?: string;
	imageData?: string;
	imageMimeType?: string;
	delete: boolean;
}

export interface ProductExtraGroup {
	productId: number;
	extraGroupId: number;
}

export interface ProductExtraGroupByBuIdInternalRequest {
	buId: number;
}

export interface ProductExtraGroupByIdInternalRequest {
	productId: number;
}

export interface ProductExtraGroupUpdateRequest {
	productId: number;
	extraGroupIds: number[];
}

export interface ProductImage {
	productId: number;
	imageData: string;
	imageMimeType: string;
}

export interface ProductImageByIdInternalRequest {
	id: number;
}

export interface ProductInventoryUpdateRequest {
	storeId: number;
	inventory: ProductInventoryUpdateRow[];
}

export interface ProductInventoryUpdateRow {
	productId: number;
	extraGroupId?: number;
	extraId?: number;
	amount?: number;
}

export interface ProductListByBusinessUnitIdAndTypeInternalRequest {
	buId: number;
	productTypes: NKProductType[];
}

export interface ProductListByBusinessUnitIdInternalRequest {
	buId: number;
}

export interface ProductPortion {
	id: number;
	productId: number;
	name: string;
	nameSv?: string;
	nameEn?: string;
	price: number;
	portion: number;
}

export interface ProductPortionsByIdInternalRequest {
	id: number;
}

export interface ProductPortionsUpdateRequest {
	id?: number;
	name: string;
	nameSv?: string;
	nameEn?: string;
	portion: number;
	price: number;
}

export interface ProductProduct {
	id: number;
	masterId: number;
	productId: number;
	price: number;
	amount: number;
	portionName?: string;
	portion: number;
}

export interface ProductProductsByIdInternalRequest {
	id: number;
}

export interface ProductProductsUpdateRequest {
	id?: number;
	productId: number;
	amount: number;
	portionName?: string;
	portion: number;
	price: number;
}

export type ProductSorting = "NAME" | "PRICE";

export interface ProductSubCategory {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	productCategoryId: number;
	name: string;
	nameSv?: string;
	nameEn?: string;
	weight: number;
	description?: string;
	descriptionSv?: string;
	descriptionEn?: string;
}

export interface ProductSubCategoryByIdInternalRequest {
	id: number;
}

export interface ProductSubCategoryListByBuIdInternalRequest {
	buId: number;
}

export interface ProductSubCategoryListByPcIdInternalRequest {
	pcId: number;
}

export interface ProductSubCategoryUpdateRequest {
	id?: number;
	productCategoryId: number;
	weight: number;
	name: string;
	nameSv?: string;
	nameEn?: string;
	description?: string;
	descriptionSv?: string;
	descriptionEn?: string;
	delete: boolean;
}

export interface ProductTheoreticalInventoryStockInternalRequest {
	storeId: number;
}

export interface ProductUpdateRequest {
	id?: number;
	businessUnitId: number;
	productCategoryId: number;
	vatCategoryId: number;
	giftCardProductId?: number;
	name: string;
	nameSv?: string;
	nameEn?: string;
	weight: number;
	openPrice: boolean;
	posProduct?: string;
	posPricingCategory?: string;
	storageUnit: number;
	posSalesChannel?: string;
	longDescription?: string;
	longDescriptionSv?: string;
	longDescriptionEn?: string;
	shortDescription?: string;
	shortDescriptionSv?: string;
	shortDescriptionEn?: string;
	allergy?: string;
	allergySv?: string;
	allergyEn?: string;
	productSubCategoryId?: number;
	outOfStock?: Date;
	portions: ProductPortionsUpdateRequest[];
	products: ProductProductsUpdateRequest[];
	catalog: ProductCatalogUpdateRequest[];
	productType: NKProductType;
	delete: boolean;
	imageData?: string;
	imageMimeType?: string;
}

export interface PruductCategorySalePrediodUpdateRequest {
	id?: number;
	productCategoryId: number;
	dayOfWeek: DayOfWeek;
	endTime: string;
	startTime: string;
	delete: boolean;
}

export interface PublicAccountOrdersResponse {
	buName: string;
	orders: Orders;
	orderStatus: NKOrderStatus;
	orderRows: PublicOrderRow[];
}

export interface PublicBrandImageInternalRequest {
	id: number;
	modifyTime: Date;
}

export interface PublicDiscountsByCodeInternalRequest {
	businessUnitId: number;
	code: string;
}

export interface PublicDiscountsByEmailInternalRequest {
	businessUnitId: number;
	email: string;
}

export interface PublicGdprLoginInternalRequest {
	m: string;
}

export interface PublicGeneralDiscountsInternalRequest {
	businessUnitId: number;
}

export interface PublicLogoImageInternalRequest {
	id: number;
	modifyTime: Date;
}

export interface PublicOrderPdfInternalRequest {
	id: number;
}

export interface PublicOrderRow {
	hasProductImage: boolean;
	orderRow: OrderRow;
	extraList: OrderRowExtra[];
}

export interface PublicOrderStatusInternalRequest {
	orderId: number;
}

export interface PublicOrderStatusResponse {
	businessUnit: BusinessUnit;
	businessUnitBrand: BusinessUnitBrand;
	orderStatus: NKOrderStatus;
	prePaidType?: WebPaymentType;
	store: Store;
	order: Orders;
	orderRowList: PublicOrderRow[];
}

export interface PublicOrderTogetherRequest {
	browserId: string;
	tableNumber: string;
	partake: boolean;
}

export interface PublicPaymentHostedRequest {
	orderId: number;
}

export interface PublicProductCategoryImageInternalRequest {
	id: number;
	modifyTime: Date;
}

export interface PublicProductImageInternalRequest {
	id: number;
	modifyTime: Date;
}

export interface PublicStoreBrandFileInternalRequest {
	id: number;
}

export interface PublicStoreByIdInternalRequest {
	id?: number;
}

export interface PublicStoreByIdResponse {
	today: Date;
	time: string;
	now: Date;
	businessUnit: BusinessUnit;
	businessUnitBrand: BusinessUnitBrand;
	storeBrand?: StoreBrandCss;
	store: Store;
	discountCodesExist: boolean;
	discountEmailsExist: boolean;
	storeHour: StoreHour[];
	storeHourOverride: StoreHourOverride[];
	storeProductCatalogList: StoreProductCatalog[];
	extraGroupList: ExtraGroup[];
	extraList: Extra[];
	extraExtraGroupList: ExtraExtraGroup[];
	productCategoryList: ProductCategory[];
	productCategoryImageExistenceList: number[];
	productCategorySalePeriodList: ProductCategorySalePeriod[];
	productSubCategoryList: ProductSubCategory[];
	productList: Product[];
	productProductList: ProductProduct[];
	productPortionList: ProductPortion[];
	productImageExistenceList: number[];
	productExtraGroupList: ProductExtraGroup[];
	specialProductList: SpecialProductContainer[];
	theoreticalInventoryStock: { [key: string]: number };
	translations: Translation[];
}

export interface ReportSalesResult {
	period: string;
	businessUnitName: string;
	storeName: string;
	storeType: NKStoreType;
	firstOrder: Date;
	lastOrder: Date;
	value: number;
	count: number;
}

export interface ReportVatReportInternalRequest {
	startDate: Date;
	endDate: Date;
	businessUnitId: number;
}

export interface ReportVatReportResult {
	vatPercent: number;
	total: number;
	vat: number;
	zeroVatTotal: number;
}

export interface SavePaymentMethodResponse {
	getUrl?: string;
	postUrl?: string;
	postData: { [key: string]: string };
}

export interface Session {
	createTime: Date;
	deleteTime?: Date;
	deleteActorId?: number;
	actorId: number;
	actorType: ActorType;
	businessGroupId?: number;
	businessUnitId?: number;
}

export interface SessionCrashRequest {
	error: string;
}

export interface SessionLoginRequest {
	username: string;
	password: string;
}

export interface SessionStatusResponse {
	abbreviatedSha: string;
	ping: boolean;
	session?: Session;
}

export interface SpecialProduct {
	id: number;
	storeId: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	name: string;
	nameSv?: string;
	nameEn?: string;
	description: string;
	descriptionSv?: string;
	descriptionEn?: string;
	startDate: Date;
	endDate: Date;
}

export interface SpecialProductByIdInternalRequest {
	id: number;
}

export interface SpecialProductByIdResponse {
	specialProduct: SpecialProduct;
	productList: number[];
}

export interface SpecialProductContainer {
	specialProduct: SpecialProduct;
	productList: number[];
}

export interface SpecialProductListInternalRequest {
	storeId: number;
}

export interface SpecialProductUpdateRequest {
	id?: number;
	storeId: number;
	name: string;
	nameSv?: string;
	nameEn?: string;
	description: string;
	descriptionSv?: string;
	descriptionEn?: string;
	startDate: Date;
	endDate: Date;
	productIdList: number[];
	delete: boolean;
}

export interface Store {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	businessUnitId: number;
	storeType: NKStoreType;
	name: string;
	nameSv?: string;
	nameEn?: string;
	description: string;
	descriptionSv?: string;
	descriptionEn?: string;
	paymentConfirmed?: string;
	paymentConfirmedSv?: string;
	paymentConfirmedEn?: string;
	allergy?: string;
	allergySv?: string;
	allergyEn?: string;
	atPremise?: string;
	atPremiseSv?: string;
	atPremiseEn?: string;
	preparingTime: number;
	minimumOrderAmount?: number;
	minimumOrderDays: number;
	minimumOrderDayChanges: number;
	availableOrderDays: number;
	shoppingCartExpireTime: number;
	redirectToFrontPageTime: number;
	showOpenHours: boolean;
	skipTime: boolean;
	storeIsClosedByDefault: boolean;
	autoSelectFirstPossibleTime: AutoSelectMode;
	orderFreeText: boolean;
	tableNumber: TableNumber;
	paymentType: NKPaymentType[];
	deliveryMethod: NKDeliveryMethod;
	onSiteArea?: string;
	onSiteMinutes?: number;
	productSorting: ProductSorting;
	closed?: Date;
	host: string[];
	gaId?: string;
	selectableLanguages: Language[];
	defaultLanguage: Language;
	orderStatus: NKOrderStatus[];
	sendEmailReceipt: boolean;
}

export interface StoreBrandCss {
	storeId: number;
	backgroundHtml: string;
	headerHtml: string;
	footerHtml: string;
	confirmationHeaderHtml: string;
	confirmationFooterHtml: string;
	style: string;
}

export interface StoreBrandCssByIdInternalRequest {
	id: number;
}

export interface StoreBrandDeleteRequest {
	id: number;
}

export interface StoreBrandFile {
	id: number;
	storeId: number;
	name: string;
	mimeType: string;
	data: string;
}

export interface StoreBrandFileByIdInternalRequest {
	id: number;
}

export interface StoreBrandFileListInternalRequest {
	storeId: number;
}

export interface StoreBrandFileUpdateRequest {
	id?: number;
	storeId: number;
	name: string;
	mimeType: string;
	data: string;
}

export interface StoreByIdInternalRequest {
	id: number;
}

export interface StoreDeliveryHour {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	storeId: number;
	dayOfWeek: DayOfWeek;
	openingTime: string;
	closingTime: string;
}

export interface StoreDeliveryHourListByIdInternalRequest {
	id: number;
}

export interface StoreDeliveryHourUpdateRequest {
	id?: number;
	storeId: number;
	dayOfWeek: DayOfWeek;
	closingTime: string;
	openingTime: string;
	delete: boolean;
}

export interface StoreDeliveryUpdateRequest {
	id: number;
	deliveryMethod: NKDeliveryMethod;
	onSiteArea?: string;
	onSiteMinutes?: number;
}

export interface StoreEmail {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	storeId?: number;
	purpose: EmailPurpose;
	language: Language;
	subject: string;
	text: string;
}

export interface StoreEmailByIdInternalRequest {
	id: number;
}

export interface StoreEmailListInternalRequest {
	storeId?: number;
}

export interface StoreEmailUpdateRequest {
	id?: number;
	storeId?: number;
	purpose: EmailPurpose;
	language: Language;
	subject: string;
	text: string;
	delete: boolean;
}

export interface StoreHour {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	storeId: number;
	dayOfWeek: DayOfWeek;
	openingTime: string;
	closingTime: string;
}

export interface StoreHourListByIdInternalRequest {
	id: number;
}

export interface StoreHourOverride {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	deleteActorId?: number;
	deleteTime?: Date;
	storeId: number;
	date: Date;
	openingTime: string;
	closingTime: string;
	description?: string;
	descriptionSv?: string;
	descriptionEn?: string;
}

export interface StoreHourOverrideByDateAndIdInternalRequest {
	id: number;
	date: Date;
}

export interface StoreHourOverrideDeleteByIdRequest {
	shoId: number;
}

export interface StoreHourOverrideListInternalRequest {
	id: number;
}

export interface StoreHourOverrideRequest {
	id?: number;
	storeId: number;
	date: Date;
	closingTime: string;
	openingTime: string;
	description?: string;
	descriptionSv?: string;
	descriptionEn?: string;
	delete: boolean;
}

export interface StoreHourUpdateRequest {
	id?: number;
	storeId: number;
	dayOfWeek: DayOfWeek;
	closingTime: string;
	openingTime: string;
	delete: boolean;
}

export interface StoreListInternalRequest {
	businessUnitId: number;
}

export interface StorePaymentInfo {
	storeId: number;
	merchantId: string;
	storeCode: string;
	paymentMessage: string;
	brandColor?: string;
	errorEmails: string[];
}

export interface StorePaymentInfoByIdInternalRequest {
	storeId: number;
}

export interface StorePaymentInfoUpdateRequest {
	storeId: number;
	merchantId: string;
	storeCode: string;
	paymentMessage: string;
	brandColor?: string;
	delete: boolean;
	errorEmails: string[];
}

export interface StoreProductCatalog {
	id: number;
	storeId: number;
	productId: number;
	discountGroupId?: number;
	startDate: Date;
	endDate?: Date;
}

export interface StoreProductCatalogListByProductIdInternalRequest {
	productId: number;
}

export interface StoreProductCatalogListInternalRequest {
	storeId: number;
}

export interface StoreProductCatalogUpdateRequest {
	storeId: number;
	catalog: StoreProductCatalogUpdateRow[];
}

export interface StoreProductCatalogUpdateRow {
	id?: number;
	productId: number;
	discountGroupId?: number;
	startDate: Date;
	endDate?: Date;
}

export interface StoreUpdateRequest {
	id?: number;
	businessUnitId: number;
	storeType: NKStoreType;
	name: string;
	nameSv?: string;
	nameEn?: string;
	description: string;
	descriptionSv?: string;
	descriptionEn?: string;
	paymentConfirmed?: string;
	paymentConfirmedSv?: string;
	paymentConfirmedEn?: string;
	allergy?: string;
	allergySv?: string;
	allergyEn?: string;
	atPremise?: string;
	atPremiseSv?: string;
	atPremiseEn?: string;
	preparingTime: number;
	minimumOrderDays: number;
	minimumOrderAmount?: number;
	minimumOrderDayChanges: number;
	availableOrderDays: number;
	shoppingCartExpireTime: number;
	redirectToFrontPageTime: number;
	showOpenHours: boolean;
	skipTime: boolean;
	storeIsClosedByDefault: boolean;
	orderFreeText: boolean;
	autoSelectFirstPossibleTime: AutoSelectMode;
	tableNumber: TableNumber;
	paymentType: NKPaymentType[];
	productSorting: ProductSorting;
	host: string[];
	gaId?: string;
	selectableLanguages: Language[];
	defaultLanguage: Language;
	orderStatus: NKOrderStatus[];
	sendEmailReceipt: boolean;
	delete: boolean;
}

export type TableNumber = "NONE" | "OPTIONAL" | "REQUIRED";

export interface TaskRuntimeData<V> {
	name: string;
	firstRun: Date;
	executions: number;
	executionsInterrupted: number;
	nextRun?: Date;
	runningStart?: Date;
	runningEnd?: Date;
	latestSuccessTime?: Date;
	latestSuccessResult?: V;
	latestFailedTime?: Date;
	validationStatus: string[];
	latestRunExceptional: boolean;
	stillRunning: boolean;
	stuck: boolean;
}

export interface Translation {
	id: number;
	createActorId: number;
	createTime: Date;
	modifyActorId: number;
	modifyTime: Date;
	storeId: number;
	language: Language;
	key: string;
	value: string;
}

export interface TranslationByIdInternalRequest {
	id: number;
}

export interface TranslationDeleteRequest {
	id: number;
}

export interface TranslationListInternalRequest {
	storeId: number;
}

export interface TranslationUpdateRequest {
	id?: number;
	storeId: number;
	language: Language;
	key: string;
	value: string;
}

export interface VatCategory {
	id: number;
	deleteTime?: Date;
	name: string;
}

export interface VivaWalletAdminDeleteIssueRequest {
	id: number;
}

export interface VivaWalletAdminIssueByIdInternalRequest {
	id: number;
}

export interface VivaWalletAdminTransactionStatusInternalRequest {
	orderId: number;
	transactionId?: string;
}

export type VivaWalletStatusId = "F" | "A" | "C" | "E" | "R" | "X" | "M" | "MA" | "MI" | "ML" | "MS" | "MW";

export interface VivaWalletTransactionStatusResponse {
	email: string;
	amount: number;
	orderCode: string;
	statusId: VivaWalletStatusId;
	fullName: string;
	insDate: string;
	cardNumber?: string;
	currencyCode: string;
	customerTrns: string;
	merchantTrns: string;
	cardUniqueReference?: string;
	transactionTypeId: number;
	recurringSupport: boolean;
	totalInstallments: number;
	cardCountryCode?: string;
	cardIssuingBank?: string;
	currentInstallment: number;
	cardTypeId?: number;
}

export interface VivaWalletWebhookIssue {
	id: number;
	createTime: Date;
	ordersId?: number;
	forwardedFor: string;
	webhookResponse: string;
	issue: string;
}

export type WebPaymentType = "BLUE_COMMERCE" | "VIVA_WALLET";

// Discovered object keys that should be read as a Date type
const dates: { [key: string]: true } = {
	"acceptedTime": true,
	"clientLastSeen": true,
	"closed": true,
	"closingDate": true,
	"createTime": true,
	"date": true,
	"deleteTime": true,
	"deliveredTime": true,
	"deliveryDate": true,
	"desiredReadyTime": true,
	"endDate": true,
	"firstOrder": true,
	"firstRun": true,
	"inventoryTime": true,
	"lastOrder": true,
	"latestFailedTime": true,
	"latestSuccessTime": true,
	"loginTimes": true,
	"modifyTime": true,
	"nextRun": true,
	"notAfter": true,
	"now": true,
	"onSiteOrderForwardTime": true,
	"openingDate": true,
	"outOfStock": true,
	"paymentOrderTimestamp": true,
	"paymentRefundStatusTimestamp": true,
	"pickupTime": true,
	"posIntegrationTime": true,
	"preparedTime": true,
	"readyTime": true,
	"runningEnd": true,
	"runningStart": true,
	"startDate": true,
	"today": true,
};

@autoinject
export class MyHttpApi {
	// Methods from fi.bel.nk.orderapi.AccountAPI
	accountGetPasswordActor(request: AccountGetPasswordActorInternalRequest) {
		return this.doGet<Actor>('Account/getPasswordActor', request);
	}
	accountGetPasswordActorUrl(request: AccountGetPasswordActorInternalRequest) {
		return this.getUrl('Account/getPasswordActor', request);
	}
	accountRegister(request: AccountRegisterRequest) {
		return this.doPut<Session>('Account/register', request);
	}
	accountRequestSetPassword(request: AccountRequestSetPasswordRequest) {
		return this.doPut<AccountRequestSetPasswordResponse>('Account/requestSetPassword', request);
	}
	accountSelf() {
		return this.doGet<Actor>('Account/self', {});
	}
	accountSelfUrl() {
		return this.getUrl('Account/self', {});
	}
	accountSetPassword(request: AccountSetPasswordRequest) {
		return this.doPut<void>('Account/setPassword', request);
	}
	accountUpdateSelf(request: ActorUpdateSelfRequest) {
		return this.doPut<void>('Account/updateSelf', request);
	}

	// Methods from fi.bel.nk.adminapi.ActorAPI
	actorAdminById(request: ActorAdminByIdInternalRequest) {
		return this.doGet<Actor>('Actor/adminById', request);
	}
	actorAdminByIdUrl(request: ActorAdminByIdInternalRequest) {
		return this.getUrl('Actor/adminById', request);
	}
	actorAdminList() {
		return this.doGet<ActorListResponse[]>('Actor/adminList', {});
	}
	actorAdminListUrl() {
		return this.getUrl('Actor/adminList', {});
	}
	actorAdminUpdate(request: ActorAdminUpdateRequest) {
		return this.doPut<Actor>('Actor/adminUpdate', request);
	}
	actorChangePasswordWithSession(request: ChangePasswordWithSessionRequest) {
		return this.doPut<void>('Actor/changePasswordWithSession', request);
	}
	actorSelf() {
		return this.doGet<GdprActor>('Actor/self', {});
	}
	actorSelfUrl() {
		return this.getUrl('Actor/self', {});
	}
	actorTerminate() {
		return this.doPut<void>('Actor/terminate', {});
	}

	// Methods from fi.bel.nk.adminapi.ADSessionAPI
	aDSessionById(request: ADSessionByIdInternalRequest) {
		return this.doGet<Session | undefined>('ADSession/byId', request);
	}
	aDSessionByIdUrl(request: ADSessionByIdInternalRequest) {
		return this.getUrl('ADSession/byId', request);
	}
	aDSessionLogin() {
		return this.doGet<unknown>('ADSession/login', {});
	}
	aDSessionLoginUrl() {
		return this.getUrl('ADSession/login', {});
	}
	aDSessionLogin2(request: ADSessionLogin2InternalRequest) {
		return this.doGet<unknown>('ADSession/login2', request);
	}
	aDSessionLogin2Url(request: ADSessionLogin2InternalRequest) {
		return this.getUrl('ADSession/login2', request);
	}

	// Methods from fi.bel.nk.adminapi.BusinessGroupAPI
	businessGroupById(request: BusinessGroupByIdInternalRequest) {
		return this.doGet<BusinessGroup>('BusinessGroup/byId', request);
	}
	businessGroupByIdUrl(request: BusinessGroupByIdInternalRequest) {
		return this.getUrl('BusinessGroup/byId', request);
	}
	businessGroupList() {
		return this.doGet<BusinessGroup[]>('BusinessGroup/list', {});
	}
	businessGroupListUrl() {
		return this.getUrl('BusinessGroup/list', {});
	}

	// Methods from fi.bel.nk.adminapi.BusinessUnitAPI
	businessUnitById(request: BusinessUnitByIdInternalRequest) {
		return this.doGet<BusinessUnit>('BusinessUnit/byId', request);
	}
	businessUnitByIdUrl(request: BusinessUnitByIdInternalRequest) {
		return this.getUrl('BusinessUnit/byId', request);
	}
	businessUnitList() {
		return this.doGet<BusinessUnit[]>('BusinessUnit/list', {});
	}
	businessUnitListUrl() {
		return this.getUrl('BusinessUnit/list', {});
	}
	businessUnitUpdate(request: BusinessUnitUpdateRequest) {
		return this.doPut<BusinessUnit>('BusinessUnit/update', request);
	}

	// Methods from fi.bel.nk.adminapi.BusinessUnitBrandAPI
	businessUnitBrandById(request: BusinessUnitBrandByIdInternalRequest) {
		return this.doGet<BusinessUnitBrand | undefined>('BusinessUnitBrand/byId', request);
	}
	businessUnitBrandByIdUrl(request: BusinessUnitBrandByIdInternalRequest) {
		return this.getUrl('BusinessUnitBrand/byId', request);
	}

	// Methods from fi.bel.nk.adminapi.BusinessUnitPaymentInfoAPI
	businessUnitPaymentInfoById(request: BusinessUnitPaymentInfoByIdInternalRequest) {
		return this.doGet<BusinessUnitPaymentInfo | undefined>('BusinessUnitPaymentInfo/byId', request);
	}
	businessUnitPaymentInfoByIdUrl(request: BusinessUnitPaymentInfoByIdInternalRequest) {
		return this.getUrl('BusinessUnitPaymentInfo/byId', request);
	}
	businessUnitPaymentInfoMake(request: BusinessUnitPaymentInfoMakeRequest) {
		return this.doPut<void>('BusinessUnitPaymentInfo/make', request);
	}
	businessUnitPaymentInfoUpdate(request: BusinessUnitPaymentInfoUpdateRequest) {
		return this.doPut<void>('BusinessUnitPaymentInfo/update', request);
	}

	// Methods from fi.bel.nk.adminapi.DiscountGroupAPI
	discountGroupById(request: DiscountGroupByIdInternalRequest) {
		return this.doGet<DiscountGroupByIdResult>('DiscountGroup/byId', request);
	}
	discountGroupByIdUrl(request: DiscountGroupByIdInternalRequest) {
		return this.getUrl('DiscountGroup/byId', request);
	}
	discountGroupList(request: DiscountGroupListInternalRequest) {
		return this.doGet<DiscountGroup[]>('DiscountGroup/list', request);
	}
	discountGroupListUrl(request: DiscountGroupListInternalRequest) {
		return this.getUrl('DiscountGroup/list', request);
	}
	discountGroupListNonGeneral(request: DiscountGroupListNonGeneralInternalRequest) {
		return this.doGet<DiscountGroup[]>('DiscountGroup/listNonGeneral', request);
	}
	discountGroupListNonGeneralUrl(request: DiscountGroupListNonGeneralInternalRequest) {
		return this.getUrl('DiscountGroup/listNonGeneral', request);
	}
	discountGroupUpdate(request: DiscountGroupUpdateRequest) {
		return this.doPut<DiscountGroup>('DiscountGroup/update', request);
	}

	// Methods from fi.bel.nk.adminapi.EmailDiscountAPI
	emailDiscountById(request: EmailDiscountByIdInternalRequest) {
		return this.doGet<EmailDiscount>('EmailDiscount/byId', request);
	}
	emailDiscountByIdUrl(request: EmailDiscountByIdInternalRequest) {
		return this.getUrl('EmailDiscount/byId', request);
	}
	emailDiscountDownload(request: EmailDiscountDownloadInternalRequest) {
		return this.doGet<string>('EmailDiscount/download', request);
	}
	emailDiscountDownloadUrl(request: EmailDiscountDownloadInternalRequest) {
		return this.getUrl('EmailDiscount/download', request);
	}
	emailDiscountList(request: EmailDiscountListInternalRequest) {
		return this.doGet<EmailDiscount[]>('EmailDiscount/list', request);
	}
	emailDiscountListUrl(request: EmailDiscountListInternalRequest) {
		return this.getUrl('EmailDiscount/list', request);
	}
	emailDiscountRemoveById(request: EmailDiscountDeleteRequest) {
		return this.doPut<void>('EmailDiscount/removeById', request);
	}
	emailDiscountUpdate(request: EmailDiscountUpdateRequest) {
		return this.doPut<void>('EmailDiscount/update', request);
	}
	emailDiscountUpdateOne(request: EmailDiscountUpdateOneRequest) {
		return this.doPut<void>('EmailDiscount/updateOne', request);
	}
	emailDiscountUpload(request: EmailDiscountUploadRequest) {
		return this.doPut<void>('EmailDiscount/upload', request);
	}

	// Methods from fi.bel.nk.adminapi.ExtraAPI
	extraById(request: ExtraByIdInternalRequest) {
		return this.doGet<Extra>('Extra/byId', request);
	}
	extraByIdUrl(request: ExtraByIdInternalRequest) {
		return this.getUrl('Extra/byId', request);
	}
	extraExtraGroupByBusinessUnitId(request: ExtraExtraGroupByBusinessUnitIdInternalRequest) {
		return this.doGet<ExtraExtraGroup[]>('Extra/extraGroupByBusinessUnitId', request);
	}
	extraExtraGroupByBusinessUnitIdUrl(request: ExtraExtraGroupByBusinessUnitIdInternalRequest) {
		return this.getUrl('Extra/extraGroupByBusinessUnitId', request);
	}
	extraExtraGroupByExtraGroupId(request: ExtraExtraGroupByExtraGroupIdInternalRequest) {
		return this.doGet<ExtraExtraGroup[]>('Extra/extraGroupByExtraGroupId', request);
	}
	extraExtraGroupByExtraGroupIdUrl(request: ExtraExtraGroupByExtraGroupIdInternalRequest) {
		return this.getUrl('Extra/extraGroupByExtraGroupId', request);
	}
	extraExtraGroupUpdate(request: ExtraExtraGroupUpdateRequest) {
		return this.doPut<ExtraExtraGroup[]>('Extra/extraGroupUpdate', request);
	}
	extraListByBusinessUnitId(request: ExtraListByBusinessUnitIdInternalRequest) {
		return this.doGet<Extra[]>('Extra/listByBusinessUnitId', request);
	}
	extraListByBusinessUnitIdUrl(request: ExtraListByBusinessUnitIdInternalRequest) {
		return this.getUrl('Extra/listByBusinessUnitId', request);
	}
	extraOutOfStock(request: PosOrNKExtraOutOfStockRequest) {
		return this.doPut<void>('Extra/outOfStock', request);
	}
	extraUpdate(request: ExtraUpdateRequest) {
		return this.doPut<Extra>('Extra/update', request);
	}

	// Methods from fi.bel.nk.adminapi.ExtraGroupAPI
	extraGroupById(request: ExtraGroupByIdInternalRequest) {
		return this.doGet<ExtraGroup>('ExtraGroup/byId', request);
	}
	extraGroupByIdUrl(request: ExtraGroupByIdInternalRequest) {
		return this.getUrl('ExtraGroup/byId', request);
	}
	extraGroupList(request: ExtraGroupListInternalRequest) {
		return this.doGet<ExtraGroup[]>('ExtraGroup/list', request);
	}
	extraGroupListUrl(request: ExtraGroupListInternalRequest) {
		return this.getUrl('ExtraGroup/list', request);
	}
	extraGroupUpdate(request: ExtraGroupUpdateRequest) {
		return this.doPut<ExtraGroup>('ExtraGroup/update', request);
	}

	// Methods from fi.bel.nk.adminapi.ForgotPasswordAPI
	forgotPasswordChangePassword(request: ChangePasswordRequest) {
		return this.doPut<void>('ForgotPassword/changePassword', request);
	}
	forgotPasswordForgotPassword(request: ForgotPasswordRequest) {
		return this.doPut<boolean>('ForgotPassword/forgotPassword', request);
	}
	forgotPasswordGetUsernameChange(request: ForgotPasswordGetUsernameChangeInternalRequest) {
		return this.doGet<ForgotPasswordUsernameResponse>('ForgotPassword/getUsernameChange', request);
	}
	forgotPasswordGetUsernameChangeUrl(request: ForgotPasswordGetUsernameChangeInternalRequest) {
		return this.getUrl('ForgotPassword/getUsernameChange', request);
	}

	// Methods from fi.bel.nk.orderapi.GdprAPI
	gdprData() {
		return this.doGet<GdprDataResponse>('Gdpr/data', {});
	}
	gdprDataUrl() {
		return this.getUrl('Gdpr/data', {});
	}
	gdprDownload() {
		return this.doGet<unknown>('Gdpr/download', {});
	}
	gdprDownloadUrl() {
		return this.getUrl('Gdpr/download', {});
	}

	// Methods from fi.bel.nk.adminapi.GiftCardAPI
	giftCardProductList(request: GiftCardProductListInternalRequest) {
		return this.doGet<GiftCardProduct[]>('GiftCard/productList', request);
	}
	giftCardProductListUrl(request: GiftCardProductListInternalRequest) {
		return this.getUrl('GiftCard/productList', request);
	}

	// Methods from fi.bel.nk.adminapi.OrderAPI
	orderAcceptById(request: NKOrPosOrderStatusRequest) {
		return this.doPut<Orders>('Order/acceptById', request);
	}
	orderById(request: OrderByIdInternalRequest) {
		return this.doGet<NKOrderByIdResponse>('Order/byId', request);
	}
	orderByIdUrl(request: OrderByIdInternalRequest) {
		return this.getUrl('Order/byId', request);
	}
	orderDeleteById(request: NKOrPosOrderStatusRequest) {
		return this.doPut<Orders>('Order/deleteById', request);
	}
	orderDeliverById(request: NKOrPosOrderStatusRequest) {
		return this.doPut<Orders>('Order/deliverById', request);
	}
	orderList(request: OrderListInternalRequest) {
		return this.doGet<OrderWithDependencies[]>('Order/list', request);
	}
	orderListUrl(request: OrderListInternalRequest) {
		return this.getUrl('Order/list', request);
	}
	orderListCanceling(request: OrderListCancelingInternalRequest) {
		return this.doGet<OrderListCancelingResponse[]>('Order/listCanceling', request);
	}
	orderListCancelingUrl(request: OrderListCancelingInternalRequest) {
		return this.getUrl('Order/listCanceling', request);
	}
	orderManuallyAcknowledgePaymentCancelFailure(request: NKOrPosOrderStatusRequest) {
		return this.doPut<Orders>('Order/manuallyAcknowledgePaymentCancelFailure', request);
	}
	orderPdf(request: OrderPdfInternalRequest) {
		return this.doGet<unknown>('Order/pdf', request);
	}
	orderPdfUrl(request: OrderPdfInternalRequest) {
		return this.getUrl('Order/pdf', request);
	}
	orderPickupById(request: NKOrPosOrderStatusRequest) {
		return this.doPut<Orders>('Order/pickupById', request);
	}
	orderPrepareById(request: NKOrPosOrderStatusRequest) {
		return this.doPut<Orders>('Order/prepareById', request);
	}
	orderResendPdf(request: OrderResendPdfRequest) {
		return this.doPut<void>('Order/resendPdf', request);
	}

	// Methods from fi.bel.nk.adminapi.ProductAPI
	productById(request: ProductByIdInternalRequest) {
		return this.doGet<Product>('Product/byId', request);
	}
	productByIdUrl(request: ProductByIdInternalRequest) {
		return this.getUrl('Product/byId', request);
	}
	productExtraGroupByBuId(request: ProductExtraGroupByBuIdInternalRequest) {
		return this.doGet<ProductExtraGroup[]>('Product/extraGroupByBuId', request);
	}
	productExtraGroupByBuIdUrl(request: ProductExtraGroupByBuIdInternalRequest) {
		return this.getUrl('Product/extraGroupByBuId', request);
	}
	productExtraGroupById(request: ProductExtraGroupByIdInternalRequest) {
		return this.doGet<ProductExtraGroup[]>('Product/extraGroupById', request);
	}
	productExtraGroupByIdUrl(request: ProductExtraGroupByIdInternalRequest) {
		return this.getUrl('Product/extraGroupById', request);
	}
	productExtraGroupUpdate(request: ProductExtraGroupUpdateRequest) {
		return this.doPut<ProductExtraGroup[]>('Product/extraGroupUpdate', request);
	}
	productImageById(request: ProductImageByIdInternalRequest) {
		return this.doGet<ProductImage | undefined>('Product/imageById', request);
	}
	productImageByIdUrl(request: ProductImageByIdInternalRequest) {
		return this.getUrl('Product/imageById', request);
	}
	productInventoryUpdate(request: ProductInventoryUpdateRequest) {
		return this.doPut<void>('Product/inventoryUpdate', request);
	}
	productListByBusinessUnitId(request: ProductListByBusinessUnitIdInternalRequest) {
		return this.doGet<Product[]>('Product/listByBusinessUnitId', request);
	}
	productListByBusinessUnitIdUrl(request: ProductListByBusinessUnitIdInternalRequest) {
		return this.getUrl('Product/listByBusinessUnitId', request);
	}
	productListByBusinessUnitIdAndType(request: ProductListByBusinessUnitIdAndTypeInternalRequest) {
		return this.doGet<Product[]>('Product/listByBusinessUnitIdAndType', request);
	}
	productListByBusinessUnitIdAndTypeUrl(request: ProductListByBusinessUnitIdAndTypeInternalRequest) {
		return this.getUrl('Product/listByBusinessUnitIdAndType', request);
	}
	productOutOfStock(request: PosOrNKProductOutOfStockRequest) {
		return this.doPut<void>('Product/outOfStock', request);
	}
	productPortionsById(request: ProductPortionsByIdInternalRequest) {
		return this.doGet<ProductPortion[]>('Product/portionsById', request);
	}
	productPortionsByIdUrl(request: ProductPortionsByIdInternalRequest) {
		return this.getUrl('Product/portionsById', request);
	}
	productProductsById(request: ProductProductsByIdInternalRequest) {
		return this.doGet<ProductProduct[]>('Product/productsById', request);
	}
	productProductsByIdUrl(request: ProductProductsByIdInternalRequest) {
		return this.getUrl('Product/productsById', request);
	}
	productTheoreticalInventoryStock(request: ProductTheoreticalInventoryStockInternalRequest) {
		return this.doGet<CalculatedProductInventory[]>('Product/theoreticalInventoryStock', request);
	}
	productTheoreticalInventoryStockUrl(request: ProductTheoreticalInventoryStockInternalRequest) {
		return this.getUrl('Product/theoreticalInventoryStock', request);
	}
	productUpdate(request: ProductUpdateRequest) {
		return this.doPut<Product>('Product/update', request);
	}

	// Methods from fi.bel.nk.adminapi.ProductCategoryAPI
	productCategoryById(request: ProductCategoryByIdInternalRequest) {
		return this.doGet<ProductCategory>('ProductCategory/byId', request);
	}
	productCategoryByIdUrl(request: ProductCategoryByIdInternalRequest) {
		return this.getUrl('ProductCategory/byId', request);
	}
	productCategoryImageById(request: ProductCategoryImageByIdInternalRequest) {
		return this.doGet<ProductCategoryImage | undefined>('ProductCategory/imageById', request);
	}
	productCategoryImageByIdUrl(request: ProductCategoryImageByIdInternalRequest) {
		return this.getUrl('ProductCategory/imageById', request);
	}
	productCategoryListByBuId(request: ProductCategoryListByBuIdInternalRequest) {
		return this.doGet<ProductCategory[]>('ProductCategory/listByBuId', request);
	}
	productCategoryListByBuIdUrl(request: ProductCategoryListByBuIdInternalRequest) {
		return this.getUrl('ProductCategory/listByBuId', request);
	}
	productCategorySalePeriodUpdate(request: PruductCategorySalePrediodUpdateRequest) {
		return this.doPut<ProductCategorySalePeriod>('ProductCategory/salePeriodUpdate', request);
	}
	productCategorySalePeriods(request: ProductCategorySalePeriodsInternalRequest) {
		return this.doGet<ProductCategorySalePeriod[]>('ProductCategory/salePeriods', request);
	}
	productCategorySalePeriodsUrl(request: ProductCategorySalePeriodsInternalRequest) {
		return this.getUrl('ProductCategory/salePeriods', request);
	}
	productCategoryUpdate(request: ProductCategoryUpdateRequest) {
		return this.doPut<ProductCategory>('ProductCategory/update', request);
	}

	// Methods from fi.bel.nk.adminapi.ProductSubCategoryAPI
	productSubCategoryById(request: ProductSubCategoryByIdInternalRequest) {
		return this.doGet<ProductSubCategory>('ProductSubCategory/byId', request);
	}
	productSubCategoryByIdUrl(request: ProductSubCategoryByIdInternalRequest) {
		return this.getUrl('ProductSubCategory/byId', request);
	}
	productSubCategoryListByBuId(request: ProductSubCategoryListByBuIdInternalRequest) {
		return this.doGet<ProductSubCategory[]>('ProductSubCategory/listByBuId', request);
	}
	productSubCategoryListByBuIdUrl(request: ProductSubCategoryListByBuIdInternalRequest) {
		return this.getUrl('ProductSubCategory/listByBuId', request);
	}
	productSubCategoryListByPcId(request: ProductSubCategoryListByPcIdInternalRequest) {
		return this.doGet<ProductSubCategory[]>('ProductSubCategory/listByPcId', request);
	}
	productSubCategoryListByPcIdUrl(request: ProductSubCategoryListByPcIdInternalRequest) {
		return this.getUrl('ProductSubCategory/listByPcId', request);
	}
	productSubCategoryUpdate(request: ProductSubCategoryUpdateRequest) {
		return this.doPut<ProductSubCategory>('ProductSubCategory/update', request);
	}

	// Methods from fi.bel.nk.orderapi.PublicAPI
	publicAccountOrders() {
		return this.doGet<PublicAccountOrdersResponse[]>('Public/accountOrders', {});
	}
	publicAccountOrdersUrl() {
		return this.getUrl('Public/accountOrders', {});
	}
	publicBrandImage(request: PublicBrandImageInternalRequest) {
		return this.doGet<unknown>('Public/brandImage', request);
	}
	publicBrandImageUrl(request: PublicBrandImageInternalRequest) {
		return this.getUrl('Public/brandImage', request);
	}
	publicCheckGiftCardOrder(request: OrderGiftCardOrderRequest) {
		return this.doPut<OutOfStockError[]>('Public/checkGiftCardOrder', request);
	}
	publicCheckOrder(request: OrderSendOrderRequest) {
		return this.doPut<OutOfStockError[]>('Public/checkOrder', request);
	}
	publicDiscountsByCode(request: PublicDiscountsByCodeInternalRequest) {
		return this.doGet<DiscountGroupByIdResult[]>('Public/discountsByCode', request);
	}
	publicDiscountsByCodeUrl(request: PublicDiscountsByCodeInternalRequest) {
		return this.getUrl('Public/discountsByCode', request);
	}
	publicDiscountsByEmail(request: PublicDiscountsByEmailInternalRequest) {
		return this.doGet<DiscountGroupByIdResult[]>('Public/discountsByEmail', request);
	}
	publicDiscountsByEmailUrl(request: PublicDiscountsByEmailInternalRequest) {
		return this.getUrl('Public/discountsByEmail', request);
	}
	publicGeneralDiscounts(request: PublicGeneralDiscountsInternalRequest) {
		return this.doGet<DiscountGroupByIdResult[]>('Public/generalDiscounts', request);
	}
	publicGeneralDiscountsUrl(request: PublicGeneralDiscountsInternalRequest) {
		return this.getUrl('Public/generalDiscounts', request);
	}
	publicGiftCardOrder(request: OrderGiftCardOrderRequest) {
		return this.doPut<Orders>('Public/giftCardOrder', request);
	}
	publicLogoImage(request: PublicLogoImageInternalRequest) {
		return this.doGet<unknown>('Public/logoImage', request);
	}
	publicLogoImageUrl(request: PublicLogoImageInternalRequest) {
		return this.getUrl('Public/logoImage', request);
	}
	publicOrderPdf(request: PublicOrderPdfInternalRequest) {
		return this.doGet<unknown>('Public/orderPdf', request);
	}
	publicOrderPdfUrl(request: PublicOrderPdfInternalRequest) {
		return this.getUrl('Public/orderPdf', request);
	}
	publicOrderStatus(request: PublicOrderStatusInternalRequest) {
		return this.doGet<PublicOrderStatusResponse>('Public/orderStatus', request);
	}
	publicOrderStatusUrl(request: PublicOrderStatusInternalRequest) {
		return this.getUrl('Public/orderStatus', request);
	}
	publicOrderTogether(request: PublicOrderTogetherRequest) {
		return this.doPut<boolean[]>('Public/orderTogether', request);
	}
	publicPaymentHosted(request: PublicPaymentHostedRequest) {
		return this.doPut<SavePaymentMethodResponse>('Public/paymentHosted', request);
	}
	publicPaymentHostedReturn(status: string, orderId: number, request: Form) {
		return this.doPost<unknown>('Public/paymentHostedReturn/' + orderId + '/' + status + '', request);
	}
	publicPaymentS2S(request: Form) {
		return this.doPost<unknown>('Public/paymentS2S', request);
	}
	publicProductCategoryImage(request: PublicProductCategoryImageInternalRequest) {
		return this.doGet<unknown>('Public/productCategoryImage', request);
	}
	publicProductCategoryImageUrl(request: PublicProductCategoryImageInternalRequest) {
		return this.getUrl('Public/productCategoryImage', request);
	}
	publicProductImage(request: PublicProductImageInternalRequest) {
		return this.doGet<unknown>('Public/productImage', request);
	}
	publicProductImageUrl(request: PublicProductImageInternalRequest) {
		return this.getUrl('Public/productImage', request);
	}
	publicSendOrder(request: OrderSendOrderRequest) {
		return this.doPut<Orders>('Public/sendOrder', request);
	}
	publicStoreBrandFile(request: PublicStoreBrandFileInternalRequest) {
		return this.doGet<unknown>('Public/storeBrandFile', request);
	}
	publicStoreBrandFileUrl(request: PublicStoreBrandFileInternalRequest) {
		return this.getUrl('Public/storeBrandFile', request);
	}
	publicStoreById(request: PublicStoreByIdInternalRequest) {
		return this.doGet<PublicStoreByIdResponse | undefined>('Public/storeById', request);
	}
	publicStoreByIdUrl(request: PublicStoreByIdInternalRequest) {
		return this.getUrl('Public/storeById', request);
	}

	// Methods from fi.bel.nk.orderapi.PublicGdprAPI
	publicGdprLogin(request: PublicGdprLoginInternalRequest) {
		return this.doGet<Session>('Gdpr/login', request);
	}
	publicGdprLoginUrl(request: PublicGdprLoginInternalRequest) {
		return this.getUrl('Gdpr/login', request);
	}
	publicGdprSendLink(request: GdprSendEmailRequest) {
		return this.doPut<void>('Gdpr/sendLink', request);
	}

	// Methods from fi.bel.nk.adminapi.ReportAPI
	reportSales() {
		return this.doGet<ReportSalesResult[]>('Report/sales', {});
	}
	reportSalesUrl() {
		return this.getUrl('Report/sales', {});
	}
	reportVatReport(request: ReportVatReportInternalRequest) {
		return this.doGet<ReportVatReportResult[]>('Report/vatReport', request);
	}
	reportVatReportUrl(request: ReportVatReportInternalRequest) {
		return this.getUrl('Report/vatReport', request);
	}

	// Methods from fi.bel.nk.adminapi.SchedulerAPI
	schedulerStatus() {
		return this.doGet<NettikauppaTaskStatusResponse[]>('Scheduler/status', {});
	}
	schedulerStatusUrl() {
		return this.getUrl('Scheduler/status', {});
	}

	// Methods from fi.bel.nk.adminapi.SessionAPI
	sessionCrash(request: SessionCrashRequest) {
		return this.doPut<void>('Session/crash', request);
	}
	sessionLogin(request: SessionLoginRequest) {
		return this.doPut<Session>('Session/login', request);
	}
	sessionLogout() {
		return this.doPut<Session>('Session/logout', {});
	}
	sessionStatus() {
		return this.doGet<SessionStatusResponse>('Session/status', {});
	}
	sessionStatusUrl() {
		return this.getUrl('Session/status', {});
	}

	// Methods from fi.bel.nk.adminapi.SpecialProductAPI
	specialProductById(request: SpecialProductByIdInternalRequest) {
		return this.doGet<SpecialProductByIdResponse>('SpecialProduct/byId', request);
	}
	specialProductByIdUrl(request: SpecialProductByIdInternalRequest) {
		return this.getUrl('SpecialProduct/byId', request);
	}
	specialProductList(request: SpecialProductListInternalRequest) {
		return this.doGet<SpecialProduct[]>('SpecialProduct/list', request);
	}
	specialProductListUrl(request: SpecialProductListInternalRequest) {
		return this.getUrl('SpecialProduct/list', request);
	}
	specialProductUpdate(request: SpecialProductUpdateRequest) {
		return this.doPut<void>('SpecialProduct/update', request);
	}

	// Methods from fi.bel.nk.adminapi.StoreAPI
	storeById(request: StoreByIdInternalRequest) {
		return this.doGet<Store>('Store/byId', request);
	}
	storeByIdUrl(request: StoreByIdInternalRequest) {
		return this.getUrl('Store/byId', request);
	}
	storeClose(request: PosOrNKStoreCloseUpdateRequest) {
		return this.doPut<void>('Store/close', request);
	}
	storeHourListById(request: StoreHourListByIdInternalRequest) {
		return this.doGet<StoreHour[]>('Store/hourListById', request);
	}
	storeHourListByIdUrl(request: StoreHourListByIdInternalRequest) {
		return this.getUrl('Store/hourListById', request);
	}
	storeHourOverrideByDateAndId(request: StoreHourOverrideByDateAndIdInternalRequest) {
		return this.doGet<StoreHourOverride>('Store/hourOverrideByDateAndId', request);
	}
	storeHourOverrideByDateAndIdUrl(request: StoreHourOverrideByDateAndIdInternalRequest) {
		return this.getUrl('Store/hourOverrideByDateAndId', request);
	}
	storeHourOverrideDeleteById(request: StoreHourOverrideDeleteByIdRequest) {
		return this.doPut<void>('Store/hourOverrideDeleteById', request);
	}
	storeHourOverrideList(request: StoreHourOverrideListInternalRequest) {
		return this.doGet<StoreHourOverride[]>('Store/hourOverrideList', request);
	}
	storeHourOverrideListUrl(request: StoreHourOverrideListInternalRequest) {
		return this.getUrl('Store/hourOverrideList', request);
	}
	storeHourOverrideUpdate(request: StoreHourOverrideRequest) {
		return this.doPut<StoreHourOverride>('Store/hourOverrideUpdate', request);
	}
	storeHourUpdate(request: StoreHourUpdateRequest) {
		return this.doPut<StoreHour>('Store/hourUpdate', request);
	}
	storeList(request: StoreListInternalRequest) {
		return this.doGet<Store[]>('Store/list', request);
	}
	storeListUrl(request: StoreListInternalRequest) {
		return this.getUrl('Store/list', request);
	}
	storeProductCatalogList(request: StoreProductCatalogListInternalRequest) {
		return this.doGet<StoreProductCatalog[]>('Store/productCatalogList', request);
	}
	storeProductCatalogListUrl(request: StoreProductCatalogListInternalRequest) {
		return this.getUrl('Store/productCatalogList', request);
	}
	storeProductCatalogListByProductId(request: StoreProductCatalogListByProductIdInternalRequest) {
		return this.doGet<StoreProductCatalog[]>('Store/productCatalogListByProductId', request);
	}
	storeProductCatalogListByProductIdUrl(request: StoreProductCatalogListByProductIdInternalRequest) {
		return this.getUrl('Store/productCatalogListByProductId', request);
	}
	storeProductCatalogUpdate(request: StoreProductCatalogUpdateRequest) {
		return this.doPut<void>('Store/productCatalogUpdate', request);
	}
	storeUpdate(request: StoreUpdateRequest) {
		return this.doPut<Store>('Store/update', request);
	}

	// Methods from fi.bel.nk.adminapi.StoreBrandAPI
	storeBrandCssById(request: StoreBrandCssByIdInternalRequest) {
		return this.doGet<StoreBrandCss | undefined>('StoreBrand/cssById', request);
	}
	storeBrandCssByIdUrl(request: StoreBrandCssByIdInternalRequest) {
		return this.getUrl('StoreBrand/cssById', request);
	}
	storeBrandCssDelete(request: StoreBrandDeleteRequest) {
		return this.doPut<void>('StoreBrand/cssDelete', request);
	}
	storeBrandCssUpdate(request: StoreBrandCss) {
		return this.doPut<void>('StoreBrand/cssUpdate', request);
	}
	storeBrandFileById(request: StoreBrandFileByIdInternalRequest) {
		return this.doGet<StoreBrandFile>('StoreBrand/fileById', request);
	}
	storeBrandFileByIdUrl(request: StoreBrandFileByIdInternalRequest) {
		return this.getUrl('StoreBrand/fileById', request);
	}
	storeBrandFileDelete(request: StoreBrandDeleteRequest) {
		return this.doPut<void>('StoreBrand/fileDelete', request);
	}
	storeBrandFileList(request: StoreBrandFileListInternalRequest) {
		return this.doGet<StoreBrandFile[]>('StoreBrand/fileList', request);
	}
	storeBrandFileListUrl(request: StoreBrandFileListInternalRequest) {
		return this.getUrl('StoreBrand/fileList', request);
	}
	storeBrandFileUpdate(request: StoreBrandFileUpdateRequest) {
		return this.doPut<void>('StoreBrand/fileUpdate', request);
	}

	// Methods from fi.bel.nk.adminapi.StoreDeliveryAPI
	storeDeliveryHourListById(request: StoreDeliveryHourListByIdInternalRequest) {
		return this.doGet<StoreDeliveryHour[]>('StoreDelivery/hourListById', request);
	}
	storeDeliveryHourListByIdUrl(request: StoreDeliveryHourListByIdInternalRequest) {
		return this.getUrl('StoreDelivery/hourListById', request);
	}
	storeDeliveryHourUpdate(request: StoreDeliveryHourUpdateRequest) {
		return this.doPut<StoreDeliveryHour>('StoreDelivery/hourUpdate', request);
	}
	storeDeliveryUpdate(request: StoreDeliveryUpdateRequest) {
		return this.doPut<Store>('StoreDelivery/update', request);
	}

	// Methods from fi.bel.nk.adminapi.StoreEmailAPI
	storeEmailById(request: StoreEmailByIdInternalRequest) {
		return this.doGet<StoreEmail>('StoreEmail/byId', request);
	}
	storeEmailByIdUrl(request: StoreEmailByIdInternalRequest) {
		return this.getUrl('StoreEmail/byId', request);
	}
	storeEmailList(request: StoreEmailListInternalRequest) {
		return this.doGet<StoreEmail[]>('StoreEmail/list', request);
	}
	storeEmailListUrl(request: StoreEmailListInternalRequest) {
		return this.getUrl('StoreEmail/list', request);
	}
	storeEmailUpdate(request: StoreEmailUpdateRequest) {
		return this.doPut<StoreEmail>('StoreEmail/update', request);
	}

	// Methods from fi.bel.nk.adminapi.StorePaymentInfoAPI
	storePaymentInfoById(request: StorePaymentInfoByIdInternalRequest) {
		return this.doGet<StorePaymentInfo | undefined>('StorePaymentInfo/byId', request);
	}
	storePaymentInfoByIdUrl(request: StorePaymentInfoByIdInternalRequest) {
		return this.getUrl('StorePaymentInfo/byId', request);
	}
	storePaymentInfoUpdate(request: StorePaymentInfoUpdateRequest) {
		return this.doPut<StorePaymentInfo | undefined>('StorePaymentInfo/update', request);
	}

	// Methods from fi.bel.nk.adminapi.VatCategoryAPI
	vatCategoryList() {
		return this.doGet<VatCategory[]>('VatCategory/list', {});
	}
	vatCategoryListUrl() {
		return this.getUrl('VatCategory/list', {});
	}

	// Methods from fi.bel.nk.adminapi.VivaWalletAdminAPI
	vivaWalletAdminCreateWebhooks() {
		return this.doPut<void>('VivaWalletAdmin/createWebhooks', {});
	}
	vivaWalletAdminDeleteIssue(request: VivaWalletAdminDeleteIssueRequest) {
		return this.doPut<void>('VivaWalletAdmin/deleteIssue', request);
	}
	vivaWalletAdminIssueById(request: VivaWalletAdminIssueByIdInternalRequest) {
		return this.doGet<VivaWalletWebhookIssue>('VivaWalletAdmin/issueById', request);
	}
	vivaWalletAdminIssueByIdUrl(request: VivaWalletAdminIssueByIdInternalRequest) {
		return this.getUrl('VivaWalletAdmin/issueById', request);
	}
	vivaWalletAdminListWebhookIssues() {
		return this.doGet<VivaWalletWebhookIssue[]>('VivaWalletAdmin/listWebhookIssues', {});
	}
	vivaWalletAdminListWebhookIssuesUrl() {
		return this.getUrl('VivaWalletAdmin/listWebhookIssues', {});
	}
	vivaWalletAdminTransactionStatus(request: VivaWalletAdminTransactionStatusInternalRequest) {
		return this.doGet<VivaWalletTransactionStatusResponse>('VivaWalletAdmin/transactionStatus', request);
	}
	vivaWalletAdminTransactionStatusUrl(request: VivaWalletAdminTransactionStatusInternalRequest) {
		return this.getUrl('VivaWalletAdmin/transactionStatus', request);
	}

	// Methods from fi.bel.nk.adminapi.TranslationAPI
	translationById(request: TranslationByIdInternalRequest) {
		return this.doGet<Translation>('Translation/byId', request);
	}
	translationByIdUrl(request: TranslationByIdInternalRequest) {
		return this.getUrl('Translation/byId', request);
	}
	translationDelete(request: TranslationDeleteRequest) {
		return this.doPut<void>('Translation/delete', request);
	}
	translationList(request: TranslationListInternalRequest) {
		return this.doGet<Translation[]>('Translation/list', request);
	}
	translationListUrl(request: TranslationListInternalRequest) {
		return this.getUrl('Translation/list', request);
	}
	translationUpdate(request: TranslationUpdateRequest) {
		return this.doPut<Translation>('Translation/update', request);
	}
/// GENERATED END ///

  public session?: Session;

  constructor(private notify: Notify) {
  }

  static toHash<T extends { id: number; }>(list: T[]) {
    let y: { [key: number]: T } = {};
    for (let x of list) {
      y[x.id] = x;
    }
    return y;
  }

  static toHashStr<T extends { id: string; }>(list: T[]) {
    let y: { [key: string]: T } = {};
    for (let x of list) {
      y[x.id] = x;
    }
    return y;
  }

  /** HTTP request encoder */
  private encode(params?: { [key: string]: any; }): string {
    if (!params) {
      params = {};
    }
    let body: string[] = [];
    for (let key in params) {
      let value: any = params[key];
      if (value === null || value === undefined) {
        continue;
      }
      if (value instanceof Array) {
        for (let val of value) {
          let v = dates[key] && val ? val.toJSON() : val;
          body.push(encodeURIComponent(key) + "=" + encodeURIComponent(v));
        }
      } else {
        value = dates[key] && value ? value.toJSON() : value;
        body.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
      }
    }
    return body.join("&");
  }

  private async fetch<T>(url: string, init: RequestInit & { headers: string[][]; }): Promise<T> {
    init.mode = "cors";
    init.credentials = init.credentials = <RequestCredentials>environment.credentials || "same-origin";
    init.redirect = "follow";
    init.cache = "no-store";

    let request = new Request(url, init);
    console.log("->", new Request(url, init));
    this.notify.spinner(1);
    try {
      let response = await fetch(request);
      console.log("<-", response);
      if (response.status === 200) {
        let data = await response.text();
        this.notify.spinner(-1);
        return JSON.parse(data, this.parseDate);
      }
      if (response.status === 204) {
        this.notify.spinner(-1);
        // @ts-ignore When server is expected to return 204, T=void (no body). TS can't prove this, though.
        return;
      }
      throw response;
    }
    catch (response: any) {
      this.notify.spinner(-1);
      /* This code path must result in failed promise.
       * If it is a 403, we render a prettier error.
       * Otherwise, we give a more technical error. */
      if (response.status === 403 && response.json) {
        let dataStr = await response.text();
        response = JSON.parse(dataStr, this.parseDate);
        this.notify.info(response.message, response.args);
      } else if (response.status && response.text) {
        let reason = await response.text();
        this.notify.info("client." + response.status, { reason });
      } else {
        this.notify.info("client.unknown", { response });
      }
      throw response;
    }
  }

  parseDate(key: string, value: any) {
    if (dates[key] && value) {
      if (value instanceof Array) {
        return value.map(x => new Date(x));
      }
      return new Date(value);
    } else {
      return value;
    }
  }

  doGet<T>(url: string, params: { [key: string]: any; }): Promise<T> {
    let fullUrl = this.getUrl(url, params);
    return this.fetch(fullUrl, {
      headers: [],
      method: "get",
    });
  }

  getUrl(url: string, params: { [key: string]: any; }): string {
    let arg = this.encode(params);
    if (arg) {
      url += "?" + arg;
    }
    return environment.server + url;
  }

  doPost<T>(url: string, params: { [key: string]: any; }): Promise<T> {
    let body = this.encode(params);
    return this.fetch<T>(environment.server + url, {
      headers: [
        ["Content-Type", "application/x-www-form-urlencoded"],
      ],
      method: "post",
      body,
    });
  }

  doPut<T>(url: string, params: { [key: string]: any; }): Promise<T> {
    return this.fetch(environment.server + url, {
      headers: [
        ["Content-Type", "application/json"],
      ],
      method: "put",
      body: JSON.stringify(params),
    });
  }
}
