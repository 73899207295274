export class InputNumberValueConverter {
  toView(value?: number) {
    if (value == undefined) {
      return undefined;
    }
    return value.toString();
  }

  fromView(value?: string) {
    if (value == undefined) {
      return undefined;
    }
    return parseFloat(value) || 0;
  }
}
