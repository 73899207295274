import { BusinessUnitBrand, MyHttpApi } from 'utils/api';
export class BrandUtil {
  private static hexToRgb(hexColor: string) {
    let result: number[] = [];
    for (let i = 1; i < 7; i += 2) {
      const color = parseInt(hexColor.substring(i, i+2), 16);
      result.push(color);
    }
    return result.join(", ");
  }

  public static getCommentedCss(brand: BusinessUnitBrand, client: MyHttpApi) {
    let bannerUrl = brand.bannerMimeType ? client.publicBrandImageUrl({ id: brand.businessUnitId, modifyTime: brand.modifyTime, }) : undefined;
    let style =
      `div.heading {
        color: ${brand.primaryBrandColorText};
        background-color: ${brand.primaryBrandColor};
        background-image: linear-gradient(to bottom, rgba(${BrandUtil.hexToRgb(brand.primaryBrandColorText)}, 0.5), rgba(${BrandUtil.hexToRgb(brand.primaryBrandColor)}, 0.5))${bannerUrl ? ", url(" + bannerUrl + ")" : ""};
      }
      div.secondary-brand {
        background-color: ${brand.secondaryBrandColor}; 
        color: ${brand.secondaryBrandColorText};
      }
      div.product-categories div.product {
        border-top: 1px solid ${brand.secondaryBrandColorText};
      }
      .product-bubble,
      button:hover,
      button {
        background-color: ${brand.actionBrandColor}; 
        color: ${brand.actionBrandColorText};
      }
    `;
    return `*/ ${style} /*`;
  }
}
