import { autoinject } from "aurelia-framework";

@autoinject
export class TranslationUtil {
  localized(obj: any, key: string, language: string) {
    if (!obj) {
      return "";
    }
    if (language !== "fi") {
      /* name => nameEn, for instance -- still a valid key. In any case, we have failure case if the lookup goes wrong. */
      let altKey = key + language.substring(0, 1).toUpperCase() + language.substring(1);
      if (obj[altKey]) {
        return obj[altKey];
      }
    }
    return obj[key] || "";
  }
}
