import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { AccountSetPasswordRequest, Actor, MyHttpApi } from 'utils/api';

@autoinject
export class SessionSetPassword {
  private request: AccountSetPasswordRequest = {
    m: "",
    password: "",
  };

  private actor?: Actor;

  private password2 = "";
  private blank = false;

  constructor(private i18n: I18N, private router: Router, private client: MyHttpApi) {
  }

  async activate(params: { m: string }) {
    this.request.m = params.m;
    this.actor = await this.client.accountGetPasswordActor({ m: params.m });
  }

  passwordValid(password: string | undefined) {
    if (!password) {
      return;
    }
    let countLower = 0;
    let countUpper = 0;
    for (let i = 0; i < password.length; i++) {
      const c = password.charAt(i);
      if (c.toLowerCase() == c) {
        countLower++;
      }
      if (c.toUpperCase() == c) {
        countUpper++;
      }
    }
    if (password.length < 8 || countLower < 1 || countUpper < 1) {
      return this.i18n.tr("server.passwordNotComplexEnough", { "length": password.length, "countLower": countLower, "countUpper": countUpper } as {});
    }
    return "";
  }


  async save() {
    if (this.request.password != this.password2) {
      return;
    }
    if (this.passwordValid(this.request.password)) {
      return;
    }

    await this.client.accountSetPassword(this.request);
    this.blank = true;
  }
}
