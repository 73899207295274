import { disableConnectQueue } from 'aurelia-binding';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Aurelia } from 'aurelia-framework';
import { I18N, TCustomAttribute } from 'aurelia-i18n';
import { PLATFORM } from 'aurelia-pal';
import environment from '../config/environment.json';
import { LocaleBackend } from './locales/locale-backend';

import { AppRouter } from 'aurelia-router';
{
  /**
   * Override the original AppRouter.generate function by removing leading "/"
   * See https://github.com/aurelia/templating-router/issues/57
   */
  (function fixGeneratedRoutes(generateOrig: Function) {
    AppRouter.prototype.generate = function (name: string, params?: any, options?: any): string {
      let href = generateOrig.call(this, name, params, options);

      if (href.charAt(0) === '/') {
        href = href.substr(1);
      }

      return href;
    }
  })(AppRouter.prototype.generate);
}

export function configure(aurelia: Aurelia): void {
  aurelia.use
    .standardConfiguration()
    .feature(PLATFORM.moduleName('resources/index'));

  aurelia.use.plugin(PLATFORM.moduleName('aurelia-i18n'), async (instance: I18N) => {
    let aliases = ['t', 'i18n'];
    TCustomAttribute.configureAliases(aliases);
    instance.i18next.use(new LocaleBackend());
    await instance.setup({
      interpolation: {
        escapeValue: false,
      },
      attributes: aliases,
      lng: localStorage.getItem("nettikauppa-language") || 'fi',
      fallbackLng: 'en',
      debug: false,
      defaultNS: "store",
      fallbackNS: "translation",
    });

    const router = aurelia.container.get(AppRouter);
    router.transformTitle = title => instance.tr(title);

    const eventAggregator = aurelia.container.get(EventAggregator);
    eventAggregator.subscribe('i18n:locale:changed', () => router.updateTitle());
  });

  disableConnectQueue();

  aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');

  aurelia.use.plugin(PLATFORM.moduleName("aurelia-animator-css"));

  aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName('app')));
}
