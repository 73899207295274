export class FormatShortDateValueConverter {
  static dateStr(date: Date) {
    return date.getDate() + "." + (date.getMonth() + 1);
  }

  static utcDateStr(date: Date) {
    return date.getUTCDate() + "." + (date.getUTCMonth() + 1) + ".";
  }

  static timeStr(date: Date) {
    return date.getHours() + ":" + FormatShortDateValueConverter.twoDigit(date.getMinutes());
  }

  static twoDigit(value: number) {
    return (value < 10 ? "0" : "") + value;
  }

  toView(value?: Date, arg?: string) {
    if (!value) {
      return "";
    }
    if (!arg) {
      return FormatShortDateValueConverter.timeStr(value) + " " + FormatShortDateValueConverter.dateStr(value);
    } else if (arg === 'noDate') {
      return FormatShortDateValueConverter.timeStr(value);
    } else if (arg === 'noTime') {
      return FormatShortDateValueConverter.utcDateStr(value);
    } else {
      return "[arg=" + arg + ", date=" + value + "]";
    }
  }

  fromView(value: any) {
    throw new Error("Can't be performed");
  }
}
