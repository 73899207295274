import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { PublicStoreByIdResponse, SavePaymentMethodResponse } from "utils/api";

interface Message {
  text: string;
  removeTimer: NodeJS.Timeout | number;
}

@autoinject
export class Notify {
  loginModal = false;
  loginCallback? = () => { /* once login succeeds, this is invoked. No action by default. */ };
  registerModal = false;
  forgotPasswordModal = false;
  store?: PublicStoreByIdResponse;

  private forward?: SavePaymentMethodResponse;
  classList?: string;
  private spinnerCount = 0;
  private messages: Message[] = [];

  constructor(private i18n: I18N) {
  }

  setForward(forward: SavePaymentMethodResponse) {
    this.forward = forward;
    if (this.forward.getUrl) {
      window.location.replace(this.forward.getUrl);
      return;
    }
    if (this.forward.postUrl) {
      const waitForFormToAppear = setInterval(() => {
        const form = <HTMLFormElement>document.getElementById("forward");
        if (form) {
          form.submit();
          clearInterval(waitForFormToAppear);
        }
      }, 100);
    }
  }

  hasForward() {
    return this.forward != undefined;
  }

  hide(message: Message) {
    this.messages = this.messages.filter(p => p !== message)
  }

  info(text: string, args?: { [key: string]: any }) {
    text = this.i18n.tr(text, args);
    let duration = args?.duration || 5000;

    let message: Message = {
      text,
      removeTimer: setTimeout(() => this.messages = this.messages.filter(p => p !== message), duration),
    };
    if(this.messages.find(p => p.text == message.text)) {
      return;
    }
    this.classList = args?.classList || "";
    this.messages.push(message);
  }

  spinner(change: number) {
    /* Spinner disabled by request. */
    /* this.spinnerCount += change; */
  }
}
