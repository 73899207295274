export class FormatTimeValueConverter {
  toView(value?: string) {
    if (!value) {
      return undefined;
    }
    return value.replace(/:00$/, "");
  }

  fromView(value: any) {
    throw new Error("Can't be performed");
  }
}
