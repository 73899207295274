export class FormatBooleanValueConverter {
  private _true = "✅";
  private _false ="❌";
  
  toView(value?: boolean) {
    return value ? this._true : this._false;
  }

  fromView(_value: any) {
    throw new Error("Can't be performed");
  }
}
