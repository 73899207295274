export class FormatMoneyValueConverter {
  toView(value?: number) {
    if (value == undefined) {
      return undefined;
    }
    return value.toLocaleString("fi-FI", { minimumFractionDigits: 2 });
  }

  fromView(value: any) {
    throw new Error("Can't be performed");
  }
}
